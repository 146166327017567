<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="내 수당 관리">
                    - 외근수당 : 고객사일지에 작성된 수당<br>
                    - 야근수당, 추가수당 : 전자결재 > 야근수당 신청서, 추가수당 신청서로 등록된 수당
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>
                            <div class="mb-20">
                                <select v-model="comp.search.year" class="w-80px" @change="comp.find">
                                    <option v-for="year in comp.years" :key="year" :value="year">{{year}}</option>
                                </select>
                                <select v-model="comp.search.month" class="w-70px ml-10" @change="comp.find">
                                    <option :value="m" v-for="m in comp.months" :key="m" >{{m}}월</option>
                                </select>
                            </div>
                            <table class="table-col" v-if="comp.total">
                                <colgroup>
                                    <col span="7">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="5">외근수당</th>
                                        <th rowspan="2">야근수당</th>
                                        <th rowspan="2">추가수당</th>
                                    </tr>
                                    <tr>
                                        <th>오픈</th>
                                        <th>참관</th>
                                        <th>타지</th>
                                        <th>합숙</th>
                                        <th class="border-r">합계</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.numberToText(comp.total.s01.price)}}</td>
                                        <td>{{comp.numberToText(comp.total.s02.price)}}</td>
                                        <td>{{comp.numberToText(comp.total.s03.price)}}</td>
                                        <td>{{comp.numberToText(comp.total.s04.price)}}</td>
                                        <td>{{comp.numberToText(comp.total.total.price)}}</td>
                                        <td>{{comp.numberToText(comp.total_overtime)}}</td>
                                        <td>{{comp.numberToText(comp.total_extrapay)}}</td>
                                    </tr>
                                    <tr class="total">
                                        <td colspan="7" class="total"><span class="color-1">총 합계 {{comp.numberToText(comp.total_sum)}}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="tabDefult-col mt-50">
                                <router-link to=""><button class="tab-default" :class="{'on':comp.tab=='outside'}" @click="comp.tab='outside'">외근수당</button></router-link>
                                <router-link to=""><button class="tab-default" :class="{'on':comp.tab=='overtime'}" @click="comp.tab='overtime'">야근수당</button></router-link>
                                <router-link to=""><button class="tab-default" :class="{'on':comp.tab=='extra'}" @click="comp.tab='extra'">추가수당</button></router-link>
                            </div>
                            <div v-if="comp.tab=='outside'">
                                <table class="table-col">
                                    <colgroup>
                                        <col width="80">
                                        <col width="140">
                                        <col width="200">
                                        <col width="130">
                                        <col width="310">
                                        <col width="200">
                                        <col width="140">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>방문일시</th>
                                            <th>고객사</th>
                                            <th>외근목적</th>
                                            <th>클래스</th>
                                            <th>금액</th>
                                            <th>등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="comp.list.length>0">
                                        <tr v-for="(itm,idx) in comp.list" :key="idx">
                                            <td>{{comp.list.length-idx}}</td>
                                            <td><router-link :to="'/customerManagement/outsideWorkView/' + itm.idx" target= '_blank'><span class="btn-view">{{itm.vdate}}</span></router-link></td>
                                            <td>{{itm.company}}</td>
                                            <td>{{itm.out_prc_type}}</td>
                                            <td class="class-info">{{itm.out_prc_class}}</td>
                                            <td>{{comp.numberToText(itm.out_prc_price)}}</td>
                                            <td>{{itm.createdate}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="comp.list.length==0">
                                        <tr>
                                            <td colspan="6">등록된 자료가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button v-if="comp.list.length>0" @click="comp.submitExpense" class="btn-default float-right mt-30"><p class="mt-5 mb-5">개인경비(수당) 제출</p><p v-if="comp.check==''" class="font-13 color-1">미제출</p><p v-if="comp.check!=''" class="font-13 color-1">{{comp.check}} 제출완료</p></button>
                            </div>

                            <table class="table-col" v-if="comp.tab=='overtime'">
                                <colgroup>
                                    <col width="80">
                                    <col width="140">
                                    <col width="*">
                                    <col width="130">
                                    <col width="200">
                                    <col width="140">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>야근일시</th>
                                        <th>근무내용</th>
                                        <th>퇴근시간</th>
                                        <th>금액</th>
                                        <th>결재 완료일</th>
                                    </tr>
                                </thead>
                                <tbody v-if="comp.overtime.length>0">
                                    <tr v-for="(itm,idx) in comp.overtime" :key="idx">
                                        <td>{{comp.overtime.length-idx}}</td>
                                        <td><router-link :to="'/documents/Document/' + itm.idx_documents" target= '_blank'><span class="btn-view">{{itm.cdate}}</span></router-link></td>
                                        <td>{{itm.memo}}</td>
                                        <td>{{itm.etime}}</td>
                                        <td>{{comp.numberToText(itm.cost)}}</td>
                                        <td>{{itm.createdate.substr(0,10)}}</td>
                                    </tr>
                                </tbody>
                                <tbody v-if="comp.overtime.length==0">
                                    <tr>
                                        <td colspan="6">등록된 자료가 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table-col" v-if="comp.tab=='extra'">
                                <colgroup>
                                    <col width="80">
                                    <col width="140">
                                    <col width="*">
                                    <col width="130">
                                    <col width="200">
                                    <col width="140">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>근무일시</th>
                                        <th>근무내용</th>
                                        <th>근무시간</th>
                                        <th>금액</th>
                                        <th>결재 완료일</th>
                                    </tr>
                                </thead>
                                <tbody v-if="comp.extrapay.length>0">
                                    <tr v-for="(itm,idx) in comp.extrapay" :key="idx">
                                        <td>{{comp.extrapay.length-idx}}</td>
                                        <td><router-link :to="'/documents/Document/' + itm.idx_documents" target= '_blank'><span class="btn-view">{{itm.cdate}}</span></router-link></td>
                                        <td>{{itm.memo}}</td>
                                        <td>{{itm.stime}} ~ {{itm.etime}}</td>
                                        <td>{{comp.numberToText(itm.cost)}}</td>
                                        <td>{{itm.createdate.substr(0,10)}}</td>
                                    </tr>
                                </tbody>
                                <tbody v-if="comp.extrapay.length==0">
                                    <tr>
                                        <td colspan="6">등록된 자료가 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>

                            
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>


<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router'


export default {
    setup() {
        const toast = useToast();
        const router = useRouter();
        const comp = reactive({
            search : {
                year : "2021",
                month : "9",
            },
            list : [],
            overtime : [],
            extrapay : [],
            total_extrapay: 0,
            total_overtime: 0,
            total_sum: 0,
            years : [],
            months : [],
            txt_filter : "",
            tab: "outside",
            total : null,
            indiv : null,
            corp : null,
            check : "",

            numberToText : (num) => {
                return Number(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            submitExpense : (ctype) => {
                let param = {params : comp.search};
                param.params.ctype = ctype;
                axios.get('/rest/extrapay/sendDocument', param).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"documents-Document-id",
                            params : { id:res.data.idx }
                        });

                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            find : () => {
                let param = {params : comp.search};
                axios.get('/rest/extrapay/myExtraPay', param).then((res)=>{
                    if( res.data.err == 0 ){
                        comp.total = res.data.total;
                        comp.list = res.data.list;
                        comp.overtime = res.data.overtime;
                        comp.extrapay = res.data.extrapay;
                        comp.total_extrapay = res.data.total_extrapay;
                        comp.total_overtime = res.data.total_overtime;
                        comp.total_sum = res.data.total_sum;
                        comp.check = res.data.check;
                        
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            modify : (idx) => {
                router.push({ 
                    path:"/documents/ExpenseInputModify/" + idx
                });
            },
        });

        onMounted(() => {
            comp.years = [];
            let ndate = new Date();
            let syear = ndate.getFullYear()-3;
            let eyear = ndate.getFullYear();
            comp.search.month = ndate.getMonth() + 1;

            comp.search.year = eyear;
            for(let i=syear;i<=eyear;i++) {
                comp.years.push(i);
            }
            for(let i=1;i<=12;i++) {
                comp.months.push(i);
            }
            comp.find();

        });

        return {comp};
    }

}
</script>

<style lang="scss" scoped>
    .class-info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
</style>